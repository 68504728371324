import { React, useState, useEffect, useRef } from "react";
import "./PartenaireForm.css";
import gsap from "gsap";
import FormHeader from "../../../../Utils/Commons/Forms/FormHeader/FormHeader";
import { useAuth } from "../../../../Security/AuthProvider";

const PartenaireForm = ({
  fetchPartenaires,
  setShowPopup,
  partenaireToEdit,
}) => {
  const { getCredentials } = useAuth();
  const formRef = useRef(null);
  const [partenaireData, setPartenaireData] = useState({
    nom: "",
    email: "",
    telephone: "",
    mot_de_passe_mail: "",
    serveur_smtp: "",
    port_smtp: "",
    secure: false,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowPopup(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [setShowPopup]);

  useEffect(() => {
    if (partenaireToEdit) {
      setPartenaireData(partenaireToEdit);
    }
  }, [partenaireToEdit]);

  const handleChange = (e) => {
    setPartenaireData({ ...partenaireData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = partenaireToEdit
        ? "/partenaires/update"
        : "/partenaires/new-partenaire";
      const method = partenaireToEdit ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({
          ...partenaireData,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });
      fetchPartenaires();
      setShowPopup(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    gsap.from(formRef.current, {
      opacity: 0.3,
      duration: 0.3,
      x: 10,
    });
  }, []);

  return (
    <div
      ref={formRef}
      className="general-data-form-container"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="form-content">
        <FormHeader
          title={
            partenaireToEdit ? "Modifier un partenaire" : "Créer un partenaire"
          }
        />
        <form className="general-data-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nom">Nom</label>
            <input
              type="text"
              id="nom"
              name="nom"
              required
              value={partenaireData.nom}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={partenaireData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="telephone">Téléphone</label>
            <input
              type="text"
              id="telephone"
              name="telephone"
              required
              value={partenaireData.telephone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="serveur_smtp">Serveur SMTP</label>
            <input
              type="text"
              id="serveur_smtp"
              name="serveur_smtp"
              required
              value={partenaireData.serveur_smtp}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="port_smtp">Port SMTP</label>
            <input
              type="number"
              id="port_smtp"
              name="port_smtp"
              required
              value={partenaireData.port_smtp}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mot_de_passe_mail">Mot de passe</label>
            <input
              type="text"
              id="mot_de_passe_mail"
              name="mot_de_passe_mail"
              required
              value={partenaireData.mot_de_passe_mail}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="secure">Secure</label>
            <input
              type="checkbox"
              id="secure"
              name="secure"
              checked={partenaireData.secure}
              onChange={(e) =>
                handleChange({
                  target: { name: "secure", value: e.target.checked },
                })
              }
            />
          </div>
          <button type="submit">
            {partenaireToEdit ? "Mettre à jour" : "Créer"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PartenaireForm;
