import { React, useState, useEffect, useRef } from "react";
import "./OperationForm.css";
import gsap from "gsap";
import FormHeader from "../../../../Utils/Commons/Forms/FormHeader/FormHeader";
import { useAuth } from "../../../../Security/AuthProvider";
import DropDownOptions from "../../../../Utils/Commons/Forms/DropDownOptions/DropDownOptions";
import RoleAttribution from "../RoleAttribution/RoleAttribution";

const OperationForm = ({ fetchOperations, setShowPopup, operationToEdit }) => {
  const { getCredentials } = useAuth();
  const formRef = useRef(null);
  const [operationData, setOperationData] = useState({
    id: "",
    duration: "",
    name: "",
  });

  const [showRolePopup, setShowRolePopup] = useState(false);

  const options = [
    {
      text: "Gérer les rôles",
      onClick: () => setShowRolePopup(true),
    },
  ];

  // Escape key listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowPopup(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (operationToEdit) {
      setOperationData(operationToEdit);
    }
  }, [operationToEdit]);

  const handleChange = (e) => {
    setOperationData({ ...operationData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = operationToEdit
        ? "/operations/update"
        : "/operations/new-operation";
      const method = operationToEdit ? "PUT" : "POST";

      if (!operationData.name || !operationData.duration) {
        console.error("Missing required fields");
        return;
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...operationData,
          id: operationToEdit ? operationToEdit.id : undefined,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      fetchOperations();
      setShowPopup(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    gsap.from(formRef.current, {
      opacity: 0.3,
      duration: 0.3,
      x: 10,
    });
  }, []);

  return (
    <div
      ref={formRef}
      className="operation-form-container general-data-form-container"
      onClick={(e) => e.stopPropagation()}
    >
      {operationToEdit && <DropDownOptions options={options} />}
      <div className="form-content">
        <FormHeader
          title={
            operationToEdit ? "Modifier une opération" : "Créer une opération"
          }
        />
        <form className="create-agent-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nom de l'opération</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={operationData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="duration">Durée (en bloc de 15 mins)</label>
            <input
              type="text"
              id="duration"
              name="duration"
              required
              value={operationData.duration}
              onChange={handleChange}
            />
          </div>
          <button type="submit">
            {operationToEdit ? "Mettre à jour" : "Créer"}
          </button>
        </form>
      </div>
      <RoleAttribution
        operation={operationToEdit}
        isOpen={showRolePopup}
        onClose={() => setShowRolePopup(false)}
      />
    </div>
  );
};

export default OperationForm;
