import React, { useEffect, useState } from "react";
import { gsap } from "gsap"; // Import GSAP
import "./NewData.css";
import FormHeader from "../../../Utils/Commons/Forms/FormHeader/FormHeader";
import { useAuth } from "../../../Security/AuthProvider";

const NewData = ({ setShowNewClients }) => {
  const { getCredentials } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    gsap.from(".new-data-container", {
      opacity: 0,
      y: -10,
      duration: 0.5,
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.target);
    const { company_name, company_password } = getCredentials();

    // Append company credentials to the form data
    formData.append("company_name", company_name);
    formData.append("company_password", company_password);
    formData.append("campagne_name", event.target.campagne_name.value);

    try {
      const response = await fetch("/client/upload-csv", {
        method: "POST",
        body: formData, // Send the FormData directly
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Upload failed");
      }

      setMessage(
        "Les données ont été ajoutées avec succès, elles sont maintenant disponibles dans l'onglet client. Les clients recevront des mails dès demain"
      );
      event.target.reset();
    } catch (error) {
      setMessage("Erreur lors de l'upload du fichier: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="popup-container" onClick={() => setShowNewClients(false)}>
      <div
        className="new-data-container general-data-form-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="form-content">
          <FormHeader title="Ajouter des clients" />
          <form className="new-data-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="file">Fichier Clients</label>
              <input
                type="file"
                name="file"
                id="file"
                accept=".csv"
                required
                placeholder="Fichier CSV"
              />
            </div>
            <div className="form-group">
              <label htmlFor="file">Nom de la campagne</label>
              <input type="text" name="campagne_name" required />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="new-data-button"
            >
              {loading ? "Chargement..." : "Envoyer"}
            </button>
          </form>
          {message && (
            <p
              className={`message ${
                message.includes("Error") ? "error" : "success"
              }`}
            >
              {message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewData;
