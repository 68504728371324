import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import "./CreateForm.css";
import FormCreation from "./FormCreation/FormCreation";
import ModifyForm from "./ModifyForm/ModifyForm";
import { useAuth } from "../../../Security/AuthProvider";

const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  // Escape key listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onCancel]);

  return (
    <div className="delete-confirmation-popup">
      <p>Are you sure you want to delete this form?</p>
      <div className="delete-confirmation-buttons">
        <button onClick={onConfirm}>Yes</button>
        <button onClick={onCancel}>No</button>
      </div>
    </div>
  );
};

function CreateForm() {
  const [showPopup, setShowPopup] = useState(false);
  const [showModifyPopup, setShowModifyPopup] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [forms, setForms] = useState([]);
  const { getCredentials } = useAuth();

  // Escape key listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowPopup(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll(".info-card");
    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        gsap.to(card, {
          "--shadow-spread": "6px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
      card.addEventListener("mouseleave", () => {
        gsap.to(card, {
          "--shadow-spread": "4px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => {});
        card.removeEventListener("mouseleave", () => {});
      });
    };
  }, [forms]);

  const deleteForm = async (id) => {
    try {
      const response = await fetch("/forms/delete", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to delete form");
      }

      await fetchForms();
      setShowDeleteConfirmation(false);
      setSelectedForm(null);
    } catch (error) {
      console.error("Error deleting form:", error);
    }
  };

  const modifyForm = async (form) => {
    try {
      const response = await fetch(`/forms/${form.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch form details");
      }

      const fullFormData = await response.json();
      console.log("Full form data:", fullFormData);

      // Set the form with complete field data
      setSelectedForm(fullFormData);
      setShowModifyPopup(form.id);
    } catch (error) {
      console.error("Error fetching form details:", error);
    }
  };

  const fetchForms = async () => {
    const response = await fetch("/forms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_name: getCredentials().company_name,
        company_password: getCredentials().company_password,
      }),
    });
    const data = await response.json();
    console.log(data);
    setForms(data);
  };

  useEffect(() => {
    if (showPopup) {
      gsap.to(".general-data-form-button", {
        rotate: 45,
        duration: 0.25,
        ease: "power1.inOut",
      });
    } else {
      gsap.to(".general-data-form-button", {
        rotate: 0,
        duration: 0.25,
        ease: "power1.inOut",
      });
    }
  }, [showPopup]);

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <div className="create-form-container creation-container">
      <div className="create-form-header creation-container-header">
        <div className="create-form-title-container title-container">
          <h1 className="create-form-title">All Forms</h1>
          <div className="create-form-title-underline"></div>
        </div>
        <button
          onClick={() => setShowPopup(!showPopup)}
          className={`general-data-form-button ${showPopup ? "close" : "open"}`}
        >
          +
        </button>
      </div>
      {forms.length === 0 ? (
        <div className="no-forms-message">No forms available</div>
      ) : (
        <div className="info-card-grid">
          {forms.map((form) => (
            <div key={form.id} className="info-card">
              <p className="info-card-name">{form.name}</p>
              <div className="info-card-name-underline"></div>
              <p className="info-card-label">Description</p>
              <p className="info-card-value">
                {form.description || "No description"}
              </p>
              <p className="info-card-label">Fields</p>
              <p className="info-card-value">
                {Array.isArray(form.fields) ? form.fields.length : 0} field(s)
              </p>
              <div className="info-card-buttons">
                <button onClick={() => modifyForm(form)}>Modify</button>
                <button
                  onClick={() => {
                    setShowDeleteConfirmation(true);
                    setSelectedForm(form);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {showPopup && (
        <div
          className="create-form-popup-container"
          onClick={() => setShowPopup(false)}
        >
          <FormCreation fetchForms={fetchForms} setShowPopup={setShowPopup} />
        </div>
      )}
      {showModifyPopup && selectedForm && (
        <div
          className="create-form-popup-container"
          onClick={() => setShowModifyPopup(null)}
        >
          <ModifyForm
            fetchForms={fetchForms}
            setShowModifyPopup={setShowModifyPopup}
            form={selectedForm}
          />
        </div>
      )}
      {showDeleteConfirmation && selectedForm && (
        <div
          className="create-form-popup-container"
          onClick={() => {
            setShowDeleteConfirmation(false);
            setSelectedForm(null);
          }}
        >
          <DeleteConfirmationPopup
            onConfirm={() => deleteForm(selectedForm.id)}
            onCancel={() => {
              setShowDeleteConfirmation(false);
              setSelectedForm(null);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default CreateForm;
