import { React, useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./CreateOperation.css";
import OperationForm from "./OperationForm/OperationForm";
import InfoCard from "../../../Utils/Commons/InfoCard/InfoCard.css";
import { useAuth } from "../../../Security/AuthProvider";

const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onCancel]);

  return (
    <div className="delete-confirmation-popup">
      <p>Voulez-vous vraiment supprimer cette opération ?</p>
      <div className="delete-confirmation-buttons">
        <button onClick={onConfirm}>Oui</button>
        <button onClick={onCancel}>Non</button>
      </div>
    </div>
  );
};

const CreateOperation = ({ setCurrentSettings = () => {} }) => {
  const [operations, setOperations] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [operationToEdit, setOperationToEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [operationToDelete, setOperationToDelete] = useState(null);
  const containerRef = useRef(null);
  const { getCredentials } = useAuth();

  const fetchOperations = async () => {
    const response = await fetch("/operations/get-all", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_name: getCredentials().company_name,
        company_password: getCredentials().company_password,
      }),
    });
    const data = await response.json();
    setOperations(data);
  };

  useEffect(() => {
    fetchOperations();
  }, []);
  useEffect(() => {
    const cards = containerRef.current.querySelectorAll(".info-card");
    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        gsap.to(card, {
          "--shadow-spread": "6px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
      card.addEventListener("mouseleave", () => {
        gsap.to(card, {
          "--shadow-spread": "4px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => {});
        card.removeEventListener("mouseleave", () => {});
      });
    };
  }, [operations]);

  const deleteOperation = async (id) => {
    await fetch("/operations/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        company_name: getCredentials().company_name,
        company_password: getCredentials().company_password,
      }),
    });
    fetchOperations();
    setShowDeleteConfirmation(false);
  };

  const handleEditOperation = (operation) => {
    setOperationToEdit(operation);
    setShowPopup(true);
  };

  const handleDeleteOperation = (operation) => {
    setOperationToDelete(operation);
    setShowDeleteConfirmation(true);
  };

  useEffect(() => {
    if (showPopup) {
      gsap.to(".general-data-form-button", {
        rotate: 45,
        duration: 0.25,
        ease: "power1.inOut",
      });
    } else {
      gsap.to(".general-data-form-button", {
        rotate: 0,
        duration: 0.25,
        ease: "power1.inOut",
      });
    }
  }, [showPopup]);
  return (
    <div
      className="creation-container create-operation-container"
      ref={containerRef}
    >
      <div className="create-operation-header creation-container-header">
        <div className="create-operation-title-container title-container">
          <h1 className="create-operation-title">Toutes les Opérations</h1>
          <div className="create-operation-title-underline"></div>
        </div>
        <button
          onClick={() => setShowPopup(!showPopup)}
          className={`general-data-form-button ${showPopup ? "close" : "open"}`}
        >
          +
        </button>
      </div>
      <div className="info-card-grid">
        {operations.map((operation) => (
          <div key={operation.id} className="info-card">
            <p className="info-card-name">{operation.name}</p>
            <div className="info-card-name-underline"></div>
            <p className="info-card-label">Durée</p>
            <p className="info-card-value">
              Durée : {operation.duration * 15} mins
            </p>
            <div className="info-card-buttons">
              <button onClick={() => handleEditOperation(operation)}>
                Modifier
              </button>
              <button onClick={() => handleDeleteOperation(operation)}>
                Supprimer
              </button>
            </div>
          </div>
        ))}
      </div>
      {showPopup && (
        <div
          className="create-operation-popup-container"
          onClick={() => {
            setShowPopup(false);
            setOperationToEdit(null);
          }}
        >
          <OperationForm
            fetchOperations={fetchOperations}
            setShowPopup={setShowPopup}
            operationToEdit={operationToEdit}
          />
        </div>
      )}
      {showDeleteConfirmation && operationToDelete && (
        <div
          className="create-operation-popup-container"
          onClick={() => {
            setShowDeleteConfirmation(false);
            setOperationToDelete(null);
          }}
        >
          <DeleteConfirmationPopup
            onConfirm={() => deleteOperation(operationToDelete.id)}
            onCancel={() => {
              setShowDeleteConfirmation(false);
              setOperationToDelete(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CreateOperation;
