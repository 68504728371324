import React from "react";
import "./FormHeader.css";

const FormHeader = ({ title }) => {
  return (
    <div className="form-header">
      <h2 className="form-title">{title}</h2>
      <div className="form-title-underline"></div>
    </div>
  );
};

export default FormHeader;
