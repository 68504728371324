import React, { useEffect, useState } from "react";
import "./Admin.css";
import NewData from "./Calendar/NewData/NewData";
import NavigationTab from "./NavigationTab/NavigationTab";
import CreateAgent from "./Settings/CreateAgent/CreateAgent";
import CreateOperation from "./Settings/CreateOperation/CreateOperation";
import CreateRole from "./Settings/CreateRole/CreateRole";
import AgentCalendar from "./Calendar/AgentCalendar/AgentCalendar";
import Clients from "./Clients/Clients";
import CreatePartenaire from "./Settings/CreatePartenaire/CreatePartenaire";
import CreateForm from "./Settings/CreateForm/CreateForm";

import "../Utils/Commons/Forms/FormLabeledText/FormLabeledText.css";
import { useAuth } from "../Security/AuthProvider";
const Admin = () => {
  const { getCredentials } = useAuth();
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [showNewClients, setShowNewClients] = useState(false);
  const [forms, setForms] = useState([]);

  const fetchAgents = async () => {
    try {
      const credentials = getCredentials();
      console.log("Admin headers", credentials);
      const response = await fetch("/agents/get-all", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_name: credentials.company_name,
          company_password: credentials.company_password,
        }),
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setAgents(data);
      } else {
        setAgents([]);
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
      setAgents([]);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <div className="admin-container">
      {agents && Array.isArray(agents) && (
        <>
          <NavigationTab
            setCurrentPage={setCurrentPage}
            agentsNames={agents.map((agent) => agent.name)}
            setCurrentAgent={setCurrentAgent}
            setShowNewClients={setShowNewClients}
          />
          <div className="admin-content">
            {currentPage === "calendar" && currentAgent && (
              <AgentCalendar currentAgent={currentAgent} />
            )}

            {currentPage === "agents" && (
              <CreateAgent fetchAgents={fetchAgents} agents={agents} />
            )}
            {currentPage === "operations" && <CreateOperation />}
            {currentPage === "partenaires" && <CreatePartenaire />}

            {currentPage === "roles" && <CreateRole />}
            {currentPage === "forms" && <CreateForm />}

            {showNewClients && (
              <NewData setShowNewClients={setShowNewClients} />
            )}
            {currentPage === "clients" && <Clients />}
          </div>
        </>
      )}
    </div>
  );
};

export default Admin;
