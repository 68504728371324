import "./ClientInfoDetails.css";
import FormHeader from "../../../../Utils/Commons/Forms/FormHeader/FormHeader";

const ClientInfoDetails = ({
  client,
  setShowDetails,
  setEditClient,
  setDeleteClient,
  setShowRequestPopup,
}) => {
  return (
    <div
      className="client-info-details general-data-form-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="form-content">
        <FormHeader title={`${client.prenom} ${client.name}`} />
        <div className="client-info-details-info-container">
          <div className="info-item">
            <label>Région</label>
            <h2 className="client-info-details-info">
              {client.region ? client.region : "Non renseigné"}
            </h2>
          </div>
          <div className="info-item">
            <label>Opération</label>
            <h2 className="client-info-details-info">
              {client.operation_name ? client.operation_name : "Non renseigné"}
            </h2>
          </div>
          <div className="info-item">
            <label>Partenaire</label>
            <h2 className="client-info-details-info">
              {client.partenaire ? client.partenaire : "Non renseigné"}
            </h2>
          </div>
          <div className="info-item">
            <label>Numéro de téléphone</label>
            <h2 className="client-info-details-info">
              {client.telephone ? client.telephone : "Non renseigné"}
            </h2>
          </div>
          <div className="info-item">
            <label>Email</label>
            <h2 className="client-info-details-info">
              {client.email ? client.email : "Non renseigné"}
            </h2>
          </div>
          <div className="info-item">
            <label>Adresse</label>
            <h2 className="client-info-details-info">
              {client.adresse ? client.adresse : "Non renseigné"}
            </h2>
          </div>
          <div className="info-item">
            <label>Campagne</label>
            <h2 className="client-info-details-info">
              {client.campagne ? client.campagne : "Non renseigné"}
            </h2>
          </div>
        </div>
        <div className="client-info-details-buttons">
          <button onClick={() => setEditClient(client)}>Modifier</button>
          <button onClick={() => setDeleteClient(client)}>Supprimer</button>
          <button onClick={() => setShowRequestPopup(true)}>
            Prendre en charge
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientInfoDetails;
