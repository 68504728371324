import React, { useEffect, useState } from "react";
import "./ClientRequest.css"; // Add your styles here
import formatBackToHours from "../../../Utils/formatBackToHours";
import RequestConfirm from "./RequestConfirm/RequestConfirm";
import { useAuth } from "../../../Security/AuthProvider"; // Correct import

const ClientRequest = ({ client, onClose, successFullRdv }) => {
  const { getCredentials } = useAuth();
  const [appointments, setAppointments] = useState([]);
  const [showRequestConfirm, setShowRequestConfirm] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const { company_name, company_password } = getCredentials();
        const response = await fetch(`/rdv/make-appointment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientToken: client.unique_link,
            company_name: company_name,
            company_password: company_password,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setAppointments(data);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    fetchAppointments();
  }, [client]);

  const handleAppointmentCreation = async (appointment, client) => {
    try {
      const { company_name, company_password } = getCredentials();
      const response = await fetch(`/rdv/new-rdv`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientName: client.name,
          agentName: appointment.agent,
          date: appointment.date,
          beginning: appointment.beginning,
          ending: appointment.beginning + appointment.time,
          adresse: client.adresse,
          operationName: client.operation_name,
          clientId: client.id,
          company_name: company_name,
          company_password: company_password,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error creating appointment:", data.error);
        return; // Don't close if there's an error
      }
      //REMOVE THIS SHIT MAKE SOMETHING COOLER
      alert("RDV mis dans l'agenda avec succès");
      successFullRdv(client);
      onClose(); // Only close on success
    } catch (error) {
      console.error("Error creating appointment:", error);
      // Don't close on error, maybe show an error message to the user
      alert("Failed to create appointment. Please try again.");
    }
  };
  const handleSelect = (appointment) => {
    setSelectedAppointment(appointment);
  };

  useEffect(() => {
    if (selectedAppointment) {
      handleAppointmentCreation(selectedAppointment, client);
    }
  }, [selectedAppointment]);

  return (
    <>
      <div className="client-request-popup">
        <h2>Possible Appointments for {client.name}</h2>
        <div className="appointment-list">
          {appointments.map((appointment) => (
            <button
              onClick={() => handleAppointmentCreation(appointment, client)}
              key={appointment.id}
              className="appointment-button"
            >
              Le {appointment.date} : Début :{" "}
              {formatBackToHours(appointment.beginning)} - Fin :{" "}
              {formatBackToHours(appointment.beginning + appointment.time)} -
              Trajet : {formatBackToHours(appointment.travelTime, 0)} - Agent :{" "}
              {appointment.agent}
            </button>
          ))}
        </div>
        <button onClick={onClose} className="close-button">
          Close
        </button>
      </div>
      {/*       {showRequestConfirm && (
        <RequestConfirm
          appointment={selectedAppointment}
          client={client}
          onClose={() => setShowRequestConfirm(false)}
        />
      )} */}
    </>
  );
};

export default ClientRequest;
