import "./EditPopup.css";
import { useState, useEffect } from "react";
import FormHeader from "../../../Utils/Commons/Forms/FormHeader/FormHeader";

const EditPopup = ({ client, fetchEditClient, setEditClient }) => {
  const [newClient, setNewClient] = useState(client);

  useEffect(() => {
    setNewClient(client);
  }, [client]);

  const handleChange = (e) => {
    setNewClient({ ...newClient, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchEditClient(newClient);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setEditClient(null);
  };

  return (
    <div className="popup-container">
      <div className="edit-popup general-data-form-container">
        <FormHeader title="Modifier le client" />
        <form onSubmit={handleSubmit} className="edit-popup-form">
          <div className="edit-popup-form-container">
            <div className="edit-popup-form-column">
              <div className="form-group">
                <label className="edit-popup-label">Nom</label>
                <input
                  className="edit-popup-input"
                  type="text"
                  name="name"
                  value={newClient.name ? newClient.name : "Non Renseigné"}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="edit-popup-label">Prénom</label>
                <input
                  className="edit-popup-input"
                  type="text"
                  name="prenom"
                  value={newClient.prenom ? newClient.prenom : "Non Renseigné"}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="edit-popup-label">Email</label>
                <input
                  className="edit-popup-input"
                  type="text"
                  name="email"
                  value={newClient.email ? newClient.email : "Non Renseigné"}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="edit-popup-label">Téléphone</label>
                <input
                  className="edit-popup-input"
                  type="text"
                  name="telephone"
                  value={
                    newClient.telephone ? newClient.telephone : "Non Renseigné"
                  }
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="edit-popup-form-column">
              <div className="form-group">
                <label className="edit-popup-label">Adresse</label>
                <input
                  className="edit-popup-input"
                  type="text"
                  name="adresse"
                  value={
                    newClient.adresse ? newClient.adresse : "Non Renseigné"
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="edit-popup-label">Code d'opération</label>
                <input
                  className="edit-popup-input"
                  type="text"
                  name="operation_name"
                  value={
                    newClient.operation_name
                      ? newClient.operation_name.toString()
                      : "Non Renseigné"
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="edit-popup-label">Région</label>
                <input
                  className="edit-popup-input"
                  type="text"
                  name="region"
                  value={newClient.region ? newClient.region : "Non Renseigné"}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="edit-popup-label">Partenaire</label>
                <input
                  className="edit-popup-input"
                  type="text"
                  name="partenaire"
                  value={
                    newClient.partenaire
                      ? newClient.partenaire
                      : "Non Renseigné"
                  }
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="edit-popup-buttons">
            <button type="submit" className="edit-popup-button">
              Enregistrer
            </button>
            <button className="edit-popup-button" onClick={handleClose}>
              Fermer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPopup;
