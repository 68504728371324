import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);
  const [poolConfig, setPoolConfig] = useState(null);
  const navigate = useNavigate();

  const login = async (company_name, company_password) => {
    try {
      console.log("company_name", company_name);
      console.log("company_password", company_password);
      const response = await fetch("/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_name: company_name,
          company_password: company_password,
        }),
      });

      const data = await response.json();

      console.log(data);
      if (!response.ok) {
        throw new Error(data.error || "Login failed");
      }

      setAdmin(data.admin);
      setPoolConfig({
        company_name: company_name,
        company_id: data.admin.company_id,
      });

      return true;
    } catch (error) {
      console.error("Login error:", error);
      return false;
    }
  };

  const logout = () => {
    setAdmin(null);
    setPoolConfig(null);
  };

  useEffect(() => {
    if (
      admin &&
      poolConfig &&
      poolConfig.company_name &&
      poolConfig.company_id &&
      admin.company_id === poolConfig.company_id
    ) {
      navigate("/admin");
    }
  }, [admin, navigate, poolConfig]);

  const getCredentials = () => {
    if (!poolConfig) return { "Content-Type": "application/json" };
    return {
      "Content-Type": "application/json",
      company_name: poolConfig.company_name,
      company_password: admin.company_password,
    };
  };

  return (
    <AuthContext.Provider
      value={{
        admin,
        login,
        logout,
        poolConfig,
        getCredentials,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
