import { React, useState, useEffect, useRef } from "react";
import "./CreationForm.css";
import gsap from "gsap";
import FormHeader from "../../../../Utils/Commons/Forms/FormHeader/FormHeader";
import { useAuth } from "../../../../Security/AuthProvider";

const CreationForm = ({ fetchAgents, setShowPopup }) => {
  const { getCredentials } = useAuth();
  const formRef = useRef(null);
  const [agentData, setAgentData] = useState({
    name: "",
    region: "",
    adresse: "",
  });

  const handleChange = (e) => {
    setAgentData({ ...agentData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log("submit");
    e.preventDefault();
    console.log(agentData);
    try {
      const credentials = getCredentials();
      console.log("sending request");
      const response = await fetch("/agents/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...agentData,
          company_name: credentials.company_name,
          company_password: credentials.company_password,
        }),
      });
      fetchAgents();
      setShowPopup(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    gsap.from(formRef.current, {
      opacity: 0.3,
      duration: 0.3,
      x: 10,
    });
  }, []);

  useEffect(() => {
    const input = document.getElementById("adresse");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: "fr" },
      fields: ["formatted_address"],
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place && place.formatted_address) {
        setAgentData((prevData) => ({
          ...prevData,
          adresse: place.formatted_address,
        }));
      }
    });
  }, []);

  return (
    <div
      ref={formRef}
      className="create-agent-form-container general-data-form-container"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="form-content">
        <FormHeader title="Créer un agent" />
        <form className="create-agent-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nom de l'agent</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={agentData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="region">Région</label>
            <input
              type="text"
              id="region"
              name="region"
              required
              value={agentData.region}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="adresse">Adresse</label>
            <input
              type="text"
              id="adresse"
              name="adresse"
              required
              value={agentData.adresse}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="adresse">Mot de Passe</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              value={agentData.password}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Créer</button>
        </form>
      </div>
    </div>
  );
};

export default CreationForm;
