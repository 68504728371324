import { React, useState, useEffect } from "react";
import "./RoleAttribution.css";
import FormHeader from "../../../../Utils/Commons/Forms/FormHeader/FormHeader";
import { useAuth } from "../../../../Security/AuthProvider";

const RoleAttribution = ({ operation, isOpen, onClose }) => {
  const { getCredentials } = useAuth();
  const [roles, setRoles] = useState([]);
  const [operationRoles, setOperationRoles] = useState([]);

  // Fetch all available roles
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch("/roles", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            company_name: getCredentials().company_name,
            company_password: getCredentials().company_password,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          setRoles(data);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    if (isOpen) {
      fetchRoles();
      fetchOperationRoles();
    }
  }, [isOpen, operation]);

  // Fetch roles assigned to this operation
  const fetchOperationRoles = async () => {
    try {
      const response = await fetch("/operations/get-operation-roles", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          operation_id: operation.id,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch operation roles");
      }
      const data = await response.json();
      setOperationRoles(data || []);
    } catch (error) {
      console.error("Error fetching operation roles:", error);
      setOperationRoles([]);
    }
  };

  const handleRoleToggle = async (roleId) => {
    const hasRole = operationRoles.some((role) => role.id === roleId);

    try {
      if (hasRole) {
        console.log("Remove role");
        await fetch("/operations/remove-role", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            operation_id: operation.id,
            role_id: roleId,
            company_name: getCredentials().company_name,
            company_password: getCredentials().company_password,
          }),
        });
      } else {
        console.log("Assign role");
        await fetch("/operations/assign-role", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            operation_id: operation.id,
            role_id: roleId,
            company_name: getCredentials().company_name,
            company_password: getCredentials().company_password,
          }),
        });
      }
      await fetchOperationRoles();
    } catch (error) {
      console.error("Error toggling role:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="role-attribution-overlay" onClick={onClose}>
      <div
        className="role-attribution-container general-data-form-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="form-content">
          <FormHeader title={`Rôles de ${operation.name}`} />
          <div className="roles-grid">
            {roles.map((role) => (
              <div key={role.id} className="role-item">
                <label className="role-checkbox">
                  <input
                    type="checkbox"
                    checked={operationRoles.some(
                      (operationRole) => operationRole.role_id === role.id
                    )}
                    onChange={() => handleRoleToggle(role.id)}
                  />
                  <span className="role-name">{role.name}</span>
                </label>
              </div>
            ))}
          </div>
          <button
            className="general-data-form-button role-attribution-button"
            onClick={() => onClose()}
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoleAttribution;
