import { React, useState, useEffect, useRef } from "react";
import "./ModifyForm.css";
import gsap from "gsap";
import FormHeader from "../../../../Utils/Commons/Forms/FormHeader/FormHeader";
import { useAuth } from "../../../../Security/AuthProvider";
import DropDownOptions from "../../../../Utils/Commons/Forms/DropDownOptions/DropDownOptions";
import RoleAttribution from "../RoleAttribution/RoleAttribution";

const ModifyForm = ({ fetchAgents, setShowModifyPopup, agent }) => {
  const { getCredentials } = useAuth();
  const [agentData, setAgentData] = useState(agent);
  const formRef = useRef(null);
  const [showRolePopup, setShowRolePopup] = useState(false);

  useEffect(() => {
    setAgentData(agent);
  }, [agent]);

  // Escape key listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowModifyPopup(null);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const handleChange = (e) => {
    setAgentData({ ...agentData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/agents/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: agent.id,
          name: agentData.name,
          region: agentData.region,
          adresse: agentData.adresse,
          password: agentData.password,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });
      fetchAgents();
      setShowModifyPopup(null);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    gsap.from(formRef.current, {
      opacity: 0.3,
      duration: 0.3,
      x: 10,
    });
  }, []);

  const options = [
    {
      text: "Gérer les rôles",
      onClick: () => setShowRolePopup(true),
    },
  ];

  return (
    <>
      <div
        className="modify-agent-form-container general-data-form-container"
        ref={formRef}
        onClick={(e) => e.stopPropagation()}
      >
        <DropDownOptions options={options} />
        <div className="form-content">
          <FormHeader title="Modifier un agent" />
          <form className="modify-agent-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Nom de l'agent</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                value={agentData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="region">Région</label>
              <input
                type="text"
                id="region"
                name="region"
                required
                value={agentData.region}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="adresse">Adresse</label>
              <input
                type="text"
                id="adresse"
                name="adresse"
                required
                value={agentData.adresse}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="adresse">Mot de Passe</label>
              <input
                type="text"
                id="password"
                name="password"
                required
                value={agentData.password}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Valider</button>
          </form>
        </div>
      </div>
      {showRolePopup && (
        <RoleAttribution
          agent={agent}
          setShowRolePopup={setShowRolePopup}
          fetchAgents={fetchAgents}
        />
      )}
    </>
  );
};

export default ModifyForm;
