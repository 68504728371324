import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import "./Register.css";
import { useAuth } from "../../../Security/AuthProvider";

const Register = () => {
  const { getCredentials } = useAuth();
  const [companyName, setCompanyName] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [dbHost, setDbHost] = useState("");
  const [dbUser, setDbUser] = useState("");
  const [dbPassword, setDbPassword] = useState("");
  const [dbName, setDbName] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    gsap.from(".form-input", {
      duration: 0.5,
      y: -20,
      opacity: 0,
      stagger: 0.1,
    });
    gsap.from(".form-button", { duration: 0.5, y: 20, opacity: 0 });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const response = await fetch("/admin/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_name: companyName,
        company_password: companyPassword,
      }),
    });

    const data = await response.json();
    if (response.ok) {
      setCompanyName("");
      setCompanyPassword("");
      setSuccess("Company registered successfully!");
    } else {
      setError(data.error);
    }
  };

  return (
    <div className="register-wrapper">
      <div className="register-container">
        <h2>Register Company</h2>
        <form onSubmit={handleSubmit}>
          <input
            className="form-input"
            type="text"
            placeholder="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
          <input
            className="form-input"
            type="password"
            placeholder="Company Password"
            value={companyPassword}
            onChange={(e) => setCompanyPassword(e.target.value)}
            required
          />
          <button className="form-button" type="submit">
            Register
          </button>
        </form>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>{success}</p>}
      </div>
    </div>
  );
};

export default Register;
