import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../Security/AuthProvider";
import "../CreateForm.css";

function ModifyForm({ fetchForms, setShowModifyPopup, form }) {
  const [formName, setFormName] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [fields, setFields] = useState([]);
  const [currentField, setCurrentField] = useState({
    name: "",
    label: "",
    type: "text",
    placeholder: "",
    required: false,
    options: [],
  });
  const [currentOption, setCurrentOption] = useState({
    value: "",
    label: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getCredentials } = useAuth();

  useEffect(() => {
    if (form) {
      setFormName(form.name || "");
      setFormDescription(form.description || "");

      // Map database fields to frontend format
      const mappedFields =
        form.fields?.map((field) => ({
          id: field.id,
          name: field.field_name,
          label: field.field_label,
          type: field.field_type,
          placeholder: field.field_placeholder || "",
          required: field.is_required === 1,
          options:
            field.options?.map((opt) => ({
              value: opt.option_value,
              label: opt.option_label,
            })) || [],
        })) || [];

      setFields(mappedFields);
      console.log("Mapped fields:", mappedFields);
    }
  }, [form]);

  const fieldTypes = [
    { value: "text", label: "Text Field" },
    { value: "checkbox", label: "Checkbox" },
    { value: "radio", label: "Radio Buttons" },
    { value: "image", label: "Image Upload" },
  ];

  const handleAddField = () => {
    if (!currentField.name || !currentField.label) {
      alert("Field name and label are required");
      return;
    }

    // Generate a safe field name if not provided
    const fieldName = currentField.name.replace(/\s+/g, "_").toLowerCase();

    // Check for duplicate field names
    if (fields.some((field) => field.name === fieldName)) {
      alert("Field name must be unique");
      return;
    }

    setFields([...fields, { ...currentField, name: fieldName }]);
    setCurrentField({
      name: "",
      label: "",
      type: "text",
      placeholder: "",
      required: false,
      options: [],
    });
  };

  const handleAddOption = () => {
    if (!currentOption.value || !currentOption.label) {
      alert("Option value and label are required");
      return;
    }

    setCurrentField({
      ...currentField,
      options: [...currentField.options, { ...currentOption }],
    });
    setCurrentOption({ value: "", label: "" });
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...currentField.options];
    updatedOptions.splice(index, 1);
    setCurrentField({
      ...currentField,
      options: updatedOptions,
    });
  };

  const handleMoveField = (index, direction) => {
    if (
      (direction === "up" && index === 0) ||
      (direction === "down" && index === fields.length - 1)
    ) {
      return;
    }

    const updatedFields = [...fields];
    const swapIndex = direction === "up" ? index - 1 : index + 1;

    [updatedFields[index], updatedFields[swapIndex]] = [
      updatedFields[swapIndex],
      updatedFields[index],
    ];

    setFields(updatedFields);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!formName) {
      setError("Form name is required");
      return;
    }

    if (fields.length === 0) {
      setError("Add at least one field to your form");
      return;
    }

    try {
      setLoading(true);
      console.log("Submitting fields:", fields);

      const response = await fetch(`/forms/${form.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formName,
          description: formDescription,
          fields: fields,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update form");
      }

      // Form updated successfully
      await fetchForms();
      setShowModifyPopup(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const needsOptions = ["radio", "checkbox", "select"].includes(
    currentField.type
  );

  return (
    <div className="form-creation-popup" onClick={(e) => e.stopPropagation()}>
      <h2>Edit Form</h2>

      {error && <div className="error-message">{error}</div>}

      <form onSubmit={handleSubmit}>
        <div className="form-section">
          <h3>Form Details</h3>
          <div className="form-group">
            <label>Form Name *</label>
            <input
              type="text"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              placeholder="Enter form name"
              required
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              value={formDescription}
              onChange={(e) => setFormDescription(e.target.value)}
              placeholder="Enter form description (optional)"
              rows="3"
            />
          </div>
        </div>

        <div className="form-section">
          <h3>Form Fields</h3>

          {fields.length > 0 && (
            <div className="fields-list">
              {fields.map((field, index) => (
                <div key={index} className="field-item">
                  <div className="field-header">
                    <h4>{field.label}</h4>
                    <div className="field-actions">
                      <button
                        type="button"
                        className="move-btn"
                        onClick={() => handleMoveField(index, "up")}
                        disabled={index === 0}
                      >
                        ↑
                      </button>
                      <button
                        type="button"
                        className="move-btn"
                        onClick={() => handleMoveField(index, "down")}
                        disabled={index === fields.length - 1}
                      >
                        ↓
                      </button>
                      <button
                        type="button"
                        className="remove-btn"
                        onClick={() => handleRemoveField(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>

                  <div className="field-details">
                    <p>
                      <strong>Type:</strong>{" "}
                      {fieldTypes.find((t) => t.value === field.type)?.label ||
                        field.type}
                    </p>
                    <p>
                      <strong>Name:</strong> {field.name}
                    </p>
                    {field.placeholder && (
                      <p>
                        <strong>Placeholder:</strong> {field.placeholder}
                      </p>
                    )}
                    <p>
                      <strong>Required:</strong> {field.required ? "Yes" : "No"}
                    </p>

                    {field.options && field.options.length > 0 && (
                      <div className="field-options">
                        <p>
                          <strong>Options:</strong>
                        </p>
                        <ul>
                          {field.options.map((option, optIdx) => (
                            <li key={optIdx}>
                              {option.label} ({option.value})
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="add-field-section">
            <h4>Add New Field</h4>
            <div className="field-form">
              <div className="form-row">
                <div className="form-group">
                  <label>Field Label *</label>
                  <input
                    type="text"
                    value={currentField.label}
                    onChange={(e) =>
                      setCurrentField({
                        ...currentField,
                        label: e.target.value,
                      })
                    }
                    placeholder="e.g., First Name"
                  />
                </div>
                <div className="form-group">
                  <label>Field Name *</label>
                  <input
                    type="text"
                    value={currentField.name}
                    onChange={(e) =>
                      setCurrentField({ ...currentField, name: e.target.value })
                    }
                    placeholder="e.g., first_name"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>Field Type</label>
                  <select
                    value={currentField.type}
                    onChange={(e) =>
                      setCurrentField({
                        ...currentField,
                        type: e.target.value,
                        options: ["radio", "checkbox", "select"].includes(
                          e.target.value
                        )
                          ? currentField.options
                          : [],
                      })
                    }
                  >
                    {fieldTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Placeholder (optional)</label>
                  <input
                    type="text"
                    value={currentField.placeholder || ""}
                    onChange={(e) =>
                      setCurrentField({
                        ...currentField,
                        placeholder: e.target.value,
                      })
                    }
                    placeholder="e.g., Enter your first name"
                  />
                </div>
              </div>

              <div className="form-group checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    checked={currentField.required}
                    onChange={(e) =>
                      setCurrentField({
                        ...currentField,
                        required: e.target.checked,
                      })
                    }
                  />
                  Required Field
                </label>
              </div>

              {needsOptions && (
                <div className="options-section">
                  <h5>Field Options</h5>

                  {currentField.options.length > 0 && (
                    <div className="options-list">
                      {currentField.options.map((option, idx) => (
                        <div key={idx} className="option-item">
                          <span>
                            {option.label} ({option.value})
                          </span>
                          <button
                            type="button"
                            className="remove-option-btn"
                            onClick={() => handleRemoveOption(idx)}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="add-option-form">
                    <div className="form-row">
                      <div className="form-group">
                        <input
                          type="text"
                          value={currentOption.label}
                          onChange={(e) =>
                            setCurrentOption({
                              ...currentOption,
                              label: e.target.value,
                            })
                          }
                          placeholder="Option Label"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          value={currentOption.value}
                          onChange={(e) =>
                            setCurrentOption({
                              ...currentOption,
                              value: e.target.value,
                            })
                          }
                          placeholder="Option Value"
                        />
                      </div>
                      <button
                        type="button"
                        className="add-option-btn"
                        onClick={handleAddOption}
                      >
                        Add Option
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <button
                type="button"
                className="add-field-btn"
                onClick={handleAddField}
              >
                Add Field
              </button>
            </div>
          </div>
        </div>

        <div className="form-actions">
          <button
            type="button"
            className="cancel-btn"
            onClick={() => setShowModifyPopup(null)}
          >
            Cancel
          </button>
          <button type="submit" className="save-btn" disabled={loading}>
            {loading ? "Updating..." : "Update Form"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ModifyForm;
