import React, { useEffect } from "react";
import "./MissionPopup.css";

const MissionPopup = ({ mission, onClose }) => {
  const getBackToHours = (timeSlot, baseHour = 8) => {
    const minutes = (timeSlot * 15) % 60;
    const hourOffset = Math.floor((timeSlot * 15) / 60);
    const hour = baseHour + hourOffset;

    return `${hour}h${minutes === 0 ? "00" : minutes}`;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="mission-popup">
      <div className="mission-popup-content">
        <h2>Détails de la Mission</h2>
        <p>
          <strong>Client:</strong>{" "}
          {mission.client_name ? mission.client_name : "Non renseigné"}
        </p>
        <p>
          <strong>Adresse:</strong>{" "}
          {mission.adresse ? mission.adresse : "Non renseigné"}
        </p>
        <p>
          <strong>Date:</strong>{" "}
          {mission.date
            ? mission.date.split("T")[0].split("-").reverse().join("/")
            : "Non renseigné"}
        </p>
        <p>
          <strong>Heure de début:</strong>{" "}
          {mission.beginning
            ? getBackToHours(mission.beginning)
            : "Non renseigné"}
        </p>
        <p>
          <strong>Heure de fin:</strong>{" "}
          {mission.ending ? getBackToHours(mission.ending) : "Non renseigné"}
        </p>
        <button onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

export default MissionPopup;
