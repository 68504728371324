import { React, useState, useEffect, useRef } from "react";
import "./RoleForm.css";
import gsap from "gsap";
import FormHeader from "../../../../Utils/Commons/Forms/FormHeader/FormHeader";
import { useAuth } from "../../../../Security/AuthProvider";

const RoleForm = ({ fetchRoles, setShowPopup, roleToEdit }) => {
  const { getCredentials } = useAuth();
  const formRef = useRef(null);
  const [roleData, setRoleData] = useState({
    name: "",
  });

  // Escape key listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowPopup(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (roleToEdit) {
      setRoleData(roleToEdit);
    }
  }, [roleToEdit]);

  const handleChange = (e) => {
    setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = roleToEdit ? "/roles/update" : "/roles/create";
      const body = roleToEdit
        ? { id: roleToEdit.id, name: roleData.name }
        : { name: roleData.name };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...body,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      fetchRoles();
      setShowPopup(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    gsap.from(formRef.current, {
      opacity: 0.3,
      duration: 0.3,
      x: 10,
    });
  }, []);

  return (
    <div
      ref={formRef}
      className="role-form-container general-data-form-container "
      onClick={(e) => e.stopPropagation()}
    >
      <div className="form-content">
        <FormHeader title={roleToEdit ? "Modifier un rôle" : "Créer un rôle"} />
        <form className="create-agent-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nom du rôle</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={roleData.name}
              onChange={handleChange}
            />
          </div>
          <button type="submit">
            {roleToEdit ? "Mettre à jour" : "Créer"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default RoleForm;
