import { useState, useRef, useEffect } from "react";
import "./DropDownOptions.css";
import gsap from "gsap";

const DropDownOptions = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionsRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      gsap.to(optionsRef.current, {
        maxHeight: 500,
        opacity: 1,
        duration: 0.3,
        ease: "power2.inOut",
      });
    } else {
      gsap.to(optionsRef.current, {
        maxHeight: 0,
        opacity: 0,
        duration: 0.3,
        ease: "power2.inOut",
      });
    }
  }, [isOpen]);

  return (
    <div className="dropdown-options-container">
      <button className="dropdown-dots" onClick={handleToggle}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </button>
      <div className="options-menu" ref={optionsRef}>
        {options.map((option, index) => (
          <button
            key={index}
            className="option-item"
            onClick={() => {
              option.onClick();
              setIsOpen(false);
            }}
          >
            {option.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DropDownOptions;
