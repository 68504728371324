import React, { useState } from "react";
import { useAuth } from "../../Security/AuthProvider"; // Ensure this path is correct
import "./Login.css";

const Login = () => {
  const [company_name, setCompanyName] = useState("");
  const [company_password, setCompanyPassword] = useState("");
  const [error, setError] = useState(null);
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      login(company_name, company_password);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-header">
          <h1>MONAGORA</h1>
          <div className="underline"></div>
        </div>
        <div className="login-form">
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label>NOM D'UTILISATEUR</label>
              <input
                type="text"
                value={company_name}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>MOT DE PASSE</label>
              <input
                type="password"
                value={company_password}
                onChange={(e) => setCompanyPassword(e.target.value)}
              />
            </div>
            <div className="button-group">
              <button type="submit" className="login-button">
                SE CONNECTER
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
