import { React, useState, useEffect, useRef } from "react";
import "./CreatePartenaire.css";
import PartenaireForm from "./PartenaireForm/PartenaireForm";
import gsap from "gsap";
import { useAuth } from "../../../Security/AuthProvider";

const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onCancel]);

  return (
    <div className="delete-confirmation-popup">
      <p>Voulez-vous vraiment supprimer ce partenaire ?</p>
      <div className="delete-confirmation-buttons">
        <button onClick={onConfirm}>Oui</button>
        <button onClick={onCancel}>Non</button>
      </div>
    </div>
  );
};

const CreatePartenaire = () => {
  const [partenaires, setPartenaires] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [partenaireToEdit, setPartenaireToEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [partenaireToDelete, setPartenaireToDelete] = useState(null);
  const containerRef = useRef(null);
  const { getCredentials } = useAuth();

  const fetchPartenaires = async () => {
    const response = await fetch("/partenaires/get-all", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_name: getCredentials().company_name,
        company_password: getCredentials().company_password,
      }),
    });
    const data = await response.json();
    setPartenaires(data);
  };

  const deletePartenaire = async (id) => {
    await fetch("/partenaires/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        company_name: getCredentials().company_name,
        company_password: getCredentials().company_password,
      }),
    });
    fetchPartenaires();
    setShowDeleteConfirmation(false);
  };

  const handleEditPartenaire = (partenaire) => {
    setPartenaireToEdit(partenaire);
    setShowPopup(true);
  };

  const handleDeletePartenaire = (partenaire) => {
    setPartenaireToDelete(partenaire);
    setShowDeleteConfirmation(true);
  };

  useEffect(() => {
    fetchPartenaires();
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll(".info-card");
    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        gsap.to(card, {
          "--shadow-spread": "6px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
      card.addEventListener("mouseleave", () => {
        gsap.to(card, {
          "--shadow-spread": "4px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
    });

    /*     const buttons = document.querySelectorAll(".info-card-buttons button");
    buttons.forEach((button) => {
      button.addEventListener("mouseenter", () => {
        gsap.fromTo(
          button,
          {
            "--shadow-spread": "0px",
            "--shadow-color": "#00000040",
          },
          {
            "--shadow-spread": "px",
            "--shadow-color": "#00000000",
            duration: 0.25,
            ease: "power1.inOut",
          }
        );
      });
    }); */

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => {});
        card.removeEventListener("mouseleave", () => {});
      });
    };
  }, [partenaires]);

  useEffect(() => {
    if (showPopup) {
      gsap.to(".general-data-form-button", {
        rotate: 45,
        duration: 0.25,
        ease: "power1.inOut",
      });
    } else {
      gsap.to(".general-data-form-button", {
        rotate: 0,
        duration: 0.25,
        ease: "power1.inOut",
      });
    }
  }, [showPopup]);
  return (
    <div
      className="creation-container create-partenaire-container"
      ref={containerRef}
    >
      <div className="create-partenaire-header creation-container-header">
        <div className="create-partenaire-title-container create-agent-title-container">
          <h1 className="create-partenaire-title">Tous les Partenaires</h1>
          <div className="create-agent-title-underline"></div>
        </div>
        <button
          onClick={() => setShowPopup(!showPopup)}
          className={`general-data-form-button ${showPopup ? "close" : "open"}`}
        >
          +
        </button>
      </div>
      <div className="info-card-grid">
        {partenaires.map((partenaire) => (
          <div key={partenaire.id} className="info-card">
            <p className="info-card-name">{partenaire.nom}</p>
            <p className="info-card-email">Email: {partenaire.email}</p>
            <p className="info-card-telephone">
              Téléphone: {partenaire.telephone || "N/A"}
            </p>
            <div className="info-card-buttons">
              <button onClick={() => handleEditPartenaire(partenaire)}>
                Modifier
              </button>
              <button onClick={() => handleDeletePartenaire(partenaire)}>
                Supprimer
              </button>
            </div>
          </div>
        ))}
      </div>
      {showPopup && (
        <div
          className="create-partenaire-popup-container"
          onClick={() => setShowPopup(false)}
        >
          <PartenaireForm
            fetchPartenaires={fetchPartenaires}
            setShowPopup={setShowPopup}
            partenaireToEdit={partenaireToEdit}
          />
        </div>
      )}
      {showDeleteConfirmation && partenaireToDelete && (
        <div
          className="create-partenaire-popup-container"
          onClick={() => setShowDeleteConfirmation(false)}
        >
          <DeleteConfirmationPopup
            onConfirm={() => deletePartenaire(partenaireToDelete.id)}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        </div>
      )}
    </div>
  );
};

export default CreatePartenaire;
