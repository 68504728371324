import "./ClientsSearchBar.css";
import { React, useState } from "react";

const ClientsSearchBar = ({ setFilters }) => {
  const [partenaireName, setPartenaireName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [operationName, setOperationName] = useState("");

  const handleSearch = () => {
    setFilters({ partenaireName, regionName, operationName });
  };

  return (
    <div className="clients-search-bar">
      <input
        type="text"
        placeholder="Nom du partenaire"
        onChange={(e) => setPartenaireName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Nom de la région"
        onChange={(e) => setRegionName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Nom de l'opération"
        onChange={(e) => setOperationName(e.target.value)}
      />
      <button onClick={handleSearch}>Rechercher</button>
    </div>
  );
};

export default ClientsSearchBar;
