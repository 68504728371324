import "./Clients.css";
import ClientInfo from "./ClientInfo/ClientInfo";
import ClientsSearchBar from "./ClientsSearchBar/ClientsSearchBar";
import { useEffect, useState } from "react";
import EditPopup from "./EditPopup/EditPopup";
import DeletePopup from "./DeletePopup/DeletePopup";
import { useAuth } from "../../Security/AuthProvider";

const Clients = () => {
  const { getCredentials } = useAuth();
  const [editClient, setEditClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [deleteClient, setDeleteClient] = useState(null);
  const [filters, setFilters] = useState({});

  const fetchClients = async () => {
    try {
      const response = await fetch("/client/get-all", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });
      const data = await response.json();
      if (data.error) {
        console.error("Error fetching clients:", data.error);
        return;
      }
      setClients(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const fetchDeleteClient = async (client) => {
    try {
      const response = await fetch(`/client/delete-client`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: client.id,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });
      const data = await response.json();
      if (data.error) {
        console.error("Error deleting client:", data.error);
        return;
      }
      setDeleteClient(null);
      setClients(
        clients.filter((deletedClient) => deletedClient.id !== client.id)
      );
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  const fetchEditClient = async (client) => {
    try {
      const { company_name, company_password } = getCredentials();
      client.company_name = company_name;
      client.company_password = company_password;
      const response = await fetch(`/client/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(client),
      });
      const data = await response.json();
      if (data.error) {
        console.error("Error editing client:", data.error);
        return;
      }
      setEditClient(null);
      fetchClients();
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  const successFullRdv = (successClient) => {
    setClients(clients.filter((client) => client.id !== successClient.id));
  };

  useEffect(() => {
    fetchClients();
  }, [filters]);

  return (
    <div className="clients">
      <ClientsSearchBar setFilters={setFilters} />
      <div className="clients-list">
        {clients &&
          clients.map((client) => (
            <ClientInfo
              client={client}
              setEditClient={setEditClient}
              setDeleteClient={setDeleteClient}
              setClients={setClients}
              successFullRdv={successFullRdv}
            />
          ))}
      </div>
      {editClient && (
        <EditPopup
          client={editClient}
          fetchEditClient={fetchEditClient}
          setEditClient={setEditClient}
        />
      )}
      {deleteClient && (
        <DeletePopup
          client={deleteClient}
          setDeleteClient={setDeleteClient}
          fetchDeleteClient={fetchDeleteClient}
        />
      )}
    </div>
  );
};

export default Clients;
