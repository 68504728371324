import "./CalendarHeader.css";

const CalendarHeader = ({ agentName }) => {
  return (
    <div className="calendar-header">
      <div className="calendar-header-content">
        <h1>{agentName}</h1>
        <div className="calendar-header-underline"></div>
      </div>
    </div>
  );
};

export default CalendarHeader;
