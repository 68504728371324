import "./ClientInfo.css";
import { React, useState, useEffect } from "react";
import ClientRequest from "../ClientRequest/ClientRequest";
import ClientInfoDetails from "./ClientInfoDetails/ClientInfoDetails";
import gsap from "gsap";
import InfoCard from "../../../Utils/Commons/InfoCard/InfoCard.css";

const ClientInfo = ({
  client,
  setEditClient,
  setDeleteClient,
  successFullRdv,
}) => {
  const [showRequestPopup, setShowRequestPopup] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const cards = document.querySelectorAll(".info-card");
    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        gsap.to(card, {
          "--shadow-spread": "6px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
      card.addEventListener("mouseleave", () => {
        gsap.to(card, {
          "--shadow-spread": "4px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => {});
        card.removeEventListener("mouseleave", () => {});
      });
    };
  }, []);

  return (
    <>
      <div className="client-info info-card">
        <p className="info-card-name">
          {client.name} {client.prenom ? client.prenom : ""}
        </p>
        <div className="info-card-name-underline"></div>
        <p className="info-card-label">Adresse</p>
        <p className="info-card-value">
          {client.adresse ? client.adresse : "Non renseigné"}
        </p>
        <p className="info-card-label">Opération</p>
        <p className="info-card-value">
          {client.operation_name ? client.operation_name : "Non renseigné"}
        </p>
        <div className="info-card-buttons">
          <button onClick={() => setShowDetails(true)}>Voir les détails</button>
        </div>
      </div>
      {showDetails && (
        <div className="popup-container" onClick={() => setShowDetails(false)}>
          <ClientInfoDetails
            client={client}
            setShowDetails={setShowDetails}
            setEditClient={setEditClient}
            setDeleteClient={setDeleteClient}
            setShowRequestPopup={setShowRequestPopup}
          />
        </div>
      )}
      {showRequestPopup && (
        <div
          className="popup-container"
          onClick={() => setShowRequestPopup(false)}
        >
          <ClientRequest
            client={client}
            onClose={() => setShowRequestPopup(false)}
            successFullRdv={successFullRdv}
          />
        </div>
      )}
    </>
  );
};

export default ClientInfo;
