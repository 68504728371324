import React, { useRef } from "react";
import "./WeekNavigator.css";
import gsap from "gsap";

const WeekNavigator = ({ changeWeek, weekStart, weekEnd }) => {
  const backArrowPathRef = useRef(null);
  const forwardArrowPathRef = useRef(null);
  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("fr-FR", {
      day: "numeric",
    });
  };

  const getMonth = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("fr-FR", {
      month: "short",
    });
  };

  const arrowHover = (ref) => {
    gsap.fromTo(
      ref.current,
      { "--s": "0px", "--o": 0.7 },
      {
        "--s": "50px",
        "--o": 0,
        duration: 1,
      }
    );
  };

  return (
    <div className="change-time">
      <div className="change-time-button">
        <button className="reculer" onClick={() => changeWeek(-1)}>
          <p className="symbol">{"<"}</p>
        </button>
        <button className="avancer" onClick={() => changeWeek(1)}>
          <p className="symbol">{">"}</p>
        </button>
      </div>
      <span>{`Semaine du (${formatDate(weekStart)} ${
        getMonth(weekStart) === getMonth(weekEnd) ? "" : getMonth(weekStart)
      } - ${formatDate(weekEnd)} ${getMonth(weekEnd)})`}</span>
      <p className="week-separator">{"|"}</p>
      <p className="timezone">{"(UTC+1) Europe/Paris"}</p>
    </div>
  );
};

export default WeekNavigator;
