import "./DeletePopup.css";

const DeletePopup = ({ client, setDeleteClient, fetchDeleteClient }) => {
  const handleDelete = (e) => {
    e.preventDefault();
    fetchDeleteClient(client);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setDeleteClient(null);
  };
  return (
    <div className="popup-container">
      <div className="delete-confirmation-popup">
        <p>Supprimer le client {client.name} ?</p>
        <div className="delete-confirmation-buttons">
          <button className="delete-popup-button" onClick={handleDelete}>
            Supprimer
          </button>
          <button className="delete-popup-button" onClick={handleClose}>
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
