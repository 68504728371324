import { React, useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./CreateRole.css";
import RoleForm from "./RoleForm/RoleForm";
import InfoCard from "../../../Utils/Commons/InfoCard/InfoCard.css";
import { useAuth } from "../../../Security/AuthProvider";

const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onCancel]);

  return (
    <div className="delete-confirmation-popup">
      <p>Voulez-vous vraiment supprimer ce rôle ?</p>
      <div className="delete-confirmation-buttons">
        <button onClick={onConfirm}>Oui</button>
        <button onClick={onCancel}>Non</button>
      </div>
    </div>
  );
};

const CreateRole = ({ setCurrentSettings = () => {} }) => {
  const [roles, setRoles] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const containerRef = useRef(null);
  const { getCredentials } = useAuth();

  const fetchRoles = async () => {
    try {
      const response = await fetch("/roles", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch roles");
      }
      const data = await response.json();
      setRoles(data || []);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    const cards = containerRef.current.querySelectorAll(".info-card");
    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        gsap.to(card, {
          "--shadow-spread": "6px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
      card.addEventListener("mouseleave", () => {
        gsap.to(card, {
          "--shadow-spread": "4px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => {});
        card.removeEventListener("mouseleave", () => {});
      });
    };
  }, [roles]);

  useEffect(() => {
    if (showPopup) {
      gsap.to(".general-data-form-button", {
        rotate: 45,
        duration: 0.25,
        ease: "power1.inOut",
      });
    } else {
      gsap.to(".general-data-form-button", {
        rotate: 0,
        duration: 0.25,
        ease: "power1.inOut",
      });
    }
  }, [showPopup]);

  const deleteRole = async (id) => {
    await fetch("/roles/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        company_name: getCredentials().company_name,
        company_password: getCredentials().company_password,
      }),
    });
    fetchRoles();
    setShowDeleteConfirmation(false);
  };

  const handleEditRole = (role) => {
    setRoleToEdit(role);
    setShowPopup(true);
  };

  const handleDeleteRole = (role) => {
    setRoleToDelete(role);
    setShowDeleteConfirmation(true);
  };

  return (
    <div
      className="creation-container create-role-container"
      ref={containerRef}
    >
      <div className="create-role-header creation-container-header">
        <div className="create-role-title-container title-container">
          <h1 className="create-role-title">Tous les Rôles</h1>
          <div className="create-role-title-underline"></div>
        </div>
        <button
          onClick={() => setShowPopup(!showPopup)}
          className={`general-data-form-button ${showPopup ? "close" : "open"}`}
        >
          +
        </button>
      </div>
      <div className="info-card-grid">
        {roles.map((role) => (
          <div key={role.id} className="info-card">
            <p className="info-card-name">{role.name}</p>
            <div className="info-card-name-underline"></div>
            <div className="info-card-buttons">
              <button onClick={() => handleEditRole(role)}>Modifier</button>
              <button onClick={() => handleDeleteRole(role)}>Supprimer</button>
            </div>
          </div>
        ))}
      </div>
      {showPopup && (
        <div
          className="create-role-popup-container"
          onClick={() => {
            setShowPopup(false);
            setRoleToEdit(null);
          }}
        >
          <RoleForm
            fetchRoles={fetchRoles}
            setShowPopup={setShowPopup}
            roleToEdit={roleToEdit}
          />
        </div>
      )}
      {showDeleteConfirmation && roleToDelete && (
        <div
          className="create-role-popup-container"
          onClick={() => {
            setShowDeleteConfirmation(false);
            setRoleToDelete(null);
          }}
        >
          <DeleteConfirmationPopup
            onConfirm={() => deleteRole(roleToDelete.id)}
            onCancel={() => {
              setShowDeleteConfirmation(false);
              setRoleToDelete(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CreateRole;
