import { React, useState, useEffect } from "react";
import { gsap } from "gsap";
import "./CreateAgent.css";
import CreationForm from "./CreationForm/CreationForm";
import ModifyForm from "./ModifyForm/ModifyForm";
import InfoCard from "../../../Utils/Commons/InfoCard/InfoCard.css";
import "../../../Utils/Commons/Forms/FormLabeledText/FormLabeledText.css";
import { useAuth } from "../../../Security/AuthProvider";

const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  // Escape key listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="delete-confirmation-popup">
      <p>Voulez-vous vraiment supprimer cet agent ?</p>
      <div className="delete-confirmation-buttons">
        <button onClick={onConfirm}>Oui</button>
        <button onClick={onCancel}>Non</button>
      </div>
    </div>
  );
};

const CreateAgent = ({ fetchAgents = () => {}, agents = [] }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showModifyPopup, setShowModifyPopup] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { getCredentials } = useAuth();
  // Escape key listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowPopup(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll(".info-card");
    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        gsap.to(card, {
          "--shadow-spread": "6px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
      card.addEventListener("mouseleave", () => {
        gsap.to(card, {
          "--shadow-spread": "4px",
          duration: 0.15,
          ease: "power1.inOut",
        });
      });
    });

    /*     const buttons = document.querySelectorAll(".info-card-buttons button");
    buttons.forEach((button) => {
      button.addEventListener("mouseenter", () => {
        gsap.fromTo(
          button,
          {
            "--shadow-spread": "0px",
            "--shadow-color": "#00000040",
          },
          {
            "--shadow-spread": "px",
            "--shadow-color": "#00000000",
            duration: 0.25,
            ease: "power1.inOut",
          }
        );
      });
    }); */

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => {});
        card.removeEventListener("mouseleave", () => {});
      });
    };
  }, [agents]);

  useEffect(() => {
    if (showPopup) {
      gsap.to(".general-data-form-button", {
        rotate: 45,
        duration: 0.25,
        ease: "power1.inOut",
      });
    } else {
      gsap.to(".general-data-form-button", {
        rotate: 0,
        duration: 0.25,
        ease: "power1.inOut",
      });
    }
  }, [showPopup]);
  const deleteAgent = (id) => {
    fetch("/agents/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        company_name: getCredentials().company_name,
        company_password: getCredentials().company_password,
      }),
    });
    fetchAgents();
    setShowDeleteConfirmation(false);
    setSelectedAgent(null);
  };

  const modifyAgent = (agent) => {
    setSelectedAgent(agent);
    setShowModifyPopup(agent.id);
  };

  return (
    <div className="create-agent-container creation-container">
      <div className="create-agent-header creation-container-header">
        <div className="create-agent-title-container title-container">
          <h1 className="create-agent-title">Tout les agents</h1>
          <div className="create-agent-title-underline"></div>
        </div>
        <button
          onClick={() => setShowPopup(!showPopup)}
          className={`general-data-form-button ${showPopup ? "close" : "open"}`}
        >
          +
        </button>
      </div>
      {agents.length === 0 ? (
        <div className="no-agents-message">Aucun agent disponible</div>
      ) : (
        <div className="info-card-grid">
          {agents.map((agent) => (
            <div key={agent.id} className="info-card">
              <p className="info-card-name">{agent.name}</p>
              <div className="info-card-name-underline"></div>
              <p className="info-card-label">Région</p>
              <p className="info-card-value">{agent.region}</p>
              <p className="info-card-label">Adresse</p>
              <p className="info-card-value">{agent.adresse}</p>
              <div className="info-card-buttons">
                <button onClick={() => modifyAgent(agent)}>Modifier</button>
                <button
                  onClick={() => {
                    setShowDeleteConfirmation(true);
                    setSelectedAgent(agent);
                  }}
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {showPopup && (
        <div
          className="create-agent-popup-container"
          onClick={() => setShowPopup(false)}
        >
          <CreationForm fetchAgents={fetchAgents} setShowPopup={setShowPopup} />
        </div>
      )}
      {showModifyPopup && selectedAgent && (
        <div
          className="create-agent-popup-container"
          onClick={() => setShowModifyPopup(null)}
        >
          <ModifyForm
            fetchAgents={fetchAgents}
            setShowModifyPopup={setShowModifyPopup}
            agent={selectedAgent}
          />
        </div>
      )}
      {showDeleteConfirmation && selectedAgent && (
        <div
          className="create-agent-popup-container"
          onClick={() => {
            setShowDeleteConfirmation(false);
            setSelectedAgent(null);
          }}
        >
          <DeleteConfirmationPopup
            onConfirm={() => deleteAgent(selectedAgent.id)}
            onCancel={() => {
              setShowDeleteConfirmation(false);
              setSelectedAgent(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CreateAgent;
