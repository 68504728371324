import { React, useState, useEffect, useRef } from "react";
import "./RoleAttribution.css";
import gsap from "gsap";
import FormHeader from "../../../../Utils/Commons/Forms/FormHeader/FormHeader";
import { useAuth } from "../../../../Security/AuthProvider";

const RoleAttribution = ({ agent, setShowRolePopup, fetchAgents }) => {
  const { getCredentials } = useAuth();
  const [roles, setRoles] = useState([]);
  const [agentRoles, setAgentRoles] = useState([]);
  const formRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const fetchRoles = async () => {
    try {
      const response = await fetch("/roles", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch roles");
      }
      const data = await response.json();
      setRoles(data || []);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    }
  };

  const fetchAgentRoles = async () => {
    try {
      const response = await fetch("/roles/agent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          agentId: agent.id,
          company_name: getCredentials().company_name,
          company_password: getCredentials().company_password,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch agent roles");
      }
      const data = await response.json();
      setAgentRoles(data || []);
    } catch (error) {
      console.error("Error fetching agent roles:", error);
      setAgentRoles([]);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchAgentRoles();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowRolePopup(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const handleRoleToggle = async (roleId) => {
    const hasRole = agentRoles.some((role) => role.id === roleId);

    try {
      if (hasRole) {
        await fetch("/roles/remove", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            agentId: agent.id,
            roleId: roleId,
            company_name: getCredentials().company_name,
            company_password: getCredentials().company_password,
          }),
        });
      } else {
        await fetch("/roles/assign", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            agentId: agent.id,
            roleId: roleId,
            company_name: getCredentials().company_name,
            company_password: getCredentials().company_password,
          }),
        });
      }
      await fetchAgentRoles();
      await fetchAgents();
    } catch (error) {
      console.error("Error toggling role:", error);
    }
  };

  useEffect(() => {
    gsap.from(formRef.current, {
      opacity: 0.3,
      duration: 0.3,
      x: 10,
    });
  }, []);

  return (
    <div
      className="role-attribution-overlay"
      onClick={() => setShowRolePopup(false)}
    >
      <div
        className="role-attribution-container general-data-form-container"
        ref={formRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="form-content">
          <FormHeader title={`Rôles de ${agent.name}`} />
          <div className="roles-grid">
            {roles.map((role) => (
              <div key={role.id} className="role-item">
                <label className="role-checkbox">
                  <input
                    type="checkbox"
                    checked={agentRoles.some(
                      (agentRole) => agentRole.id === role.id
                    )}
                    onChange={() => handleRoleToggle(role.id)}
                  />
                  <span className="role-name">{role.name}</span>
                </label>
              </div>
            ))}
          </div>
          <button
            className="general-data-form-button role-attribution-button"
            onClick={() => setShowRolePopup(false)}
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoleAttribution;
