import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Security/AuthProvider";
import Login from "./Admin/Login/Login";
import Admin from "./Admin/Admin";
import PrivateRoute from "./Security/AdminRoute";
import "./App.css";
import AdminRoute from "./Security/AdminRoute";
import User from "./User/User";
import Register from "./Admin/Login/Register/Register";

const App = () => {
  return (
    <React.StrictMode>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/admin" element={<AdminRoute />} />
            <Route path="*" element={<User />} />
          </Routes>
        </AuthProvider>
      </Router>
    </React.StrictMode>
  );
};

export default App;
